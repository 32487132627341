@charset "UTF-8";
@import url("https://use.typekit.net/nim5iqm.css");
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: none;
}

input,
button,
textarea,
select {
  margin: 0;
  padding: 0;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-size: 99%;
  border-radius: 0;
}

textarea {
  resize: none;
}

input::placeholder,
textarea::placeholder {
  color: #B0AFAF;
  opacity: 1;
}

textarea,
button,
input[type=email],
input[type=tel],
input[type=text],
input[type=submit],
input[type=search] {
  -webkit-appearance: none;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button {
  display: none;
}

textarea[disabled],
button[disabled],
input[type=email][disabled],
input[type=tel][disabled],
input[type=text][disabled],
input[type=submit][disabled],
input[type=search][disabled] {
  cursor: not-allowed;
}

select[disabled],
button[disabled],
input[disabled],
input[type=checkbox][disabled] + label,
input[type=radio][disabled] + label {
  cursor: not-allowed;
}

table {
  font-size: inherit;
  border-collapse: collapse;
  border-spacing: 0;
}

table td, table th {
  padding: 4px 15px;
  vertical-align: top;
}

tr > :first-child {
  padding-left: 0;
}

th {
  font-weight: 500;
}

caption,
strong,
b {
  font-weight: 700;
}

ul ul {
  margin-bottom: 0;
}

form,
figure,
blockquote {
  margin: 0;
  padding: 0;
}

img,
iframe {
  max-width: 100%;
  height: auto;
  border: none;
  vertical-align: middle;
}

header,
footer,
nav,
section,
main,
aside,
article,
figure,
figcaption {
  display: block;
}

html {
  font-size: 16px;
}

body {
  line-height: 1.625;
  font-family: "rival-sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #595959;
  letter-spacing: 0.2px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

a {
  color: inherit;
  text-decoration: none;
}

caption,
th,
td {
  text-align: left;
  text-align: start;
}

abbr,
acronym {
  font-variant: normal;
  border-bottom: 1px dotted #3c3c3c;
  cursor: help;
}

blockquote,
q {
  quotes: none;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

del {
  text-decoration: line-through;
}

ins {
  text-decoration: none;
}

pre,
code {
  font-family: monospace;
}

/* Vertical align */
.float_left {
  float: left;
}

.float_right {
  float: right;
}

/* Clear floats */
.clear,
#clear {
  height: 0.1px;
  font-size: 0.1px;
  line-height: 0.1px;
  clear: both;
}

/* Hide invisible elements */
.invisible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.list-items ul, .list-items li,
.mod_navigation ul,
.mod_navigation li,
.mod_changelanguage ul,
.mod_changelanguage li,
.mod_breadcrumb ul,
.mod_breadcrumb li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

@media screen and (min-width: 1220px) {
  .section-main .mod_article__inside,
  .section-service .mod_article__inside {
    max-width: 1260px;
    margin: 0 auto;
  }
  .section-main .mod_article__inside:after,
  .section-service .mod_article__inside:after {
    display: table;
    clear: both;
    content: "";
  }
}
.placeholder {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eee 8%, #e5e5e5 18%, #eee 33%);
  background-size: 1000px 104px;
  position: relative;
  overflow: hidden;
}
.placeholder--lines {
  height: 1.2em;
  margin-bottom: 0.2em;
}
.placeholder--headline {
  background: linear-gradient(to right, #ddd 8%, #ccc 18%, #ddd 33%);
  background-size: 1000px 104px;
}
.placeholder--block {
  width: 25px;
  height: 27px;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes unsticky {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes sticky {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.widget {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 0 18px;
  color: #595959;
  font-size: inherit;
}
.widget > label {
  position: absolute;
  left: 13px;
  top: -12px;
  z-index: 1;
  margin: 0 1px 0 0;
  padding: 0 10px;
  background: #fff;
  font-size: 14px;
  line-height: 25px;
  height: 25px;
  max-width: calc(100% - 20px);
  transition: all 0.2s;
  cursor: text;
  overflow: hidden;
  word-break: break-all;
}
.widget select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.widget textarea {
  width: 100%;
}
.widget input.text,
.widget input.captcha,
.widget select,
.widget textarea {
  width: 100%;
  padding: 16px 23px 8px;
  font-size: inherit;
  border: 1.5px solid #B0AFAF;
  border-radius: 0;
}
.widget input.text.no-label,
.widget input.captcha.no-label,
.widget select.no-label,
.widget textarea.no-label {
  padding-top: 15px;
  padding-bottom: 13px;
}
.widget input.text::placeholder,
.widget input.captcha::placeholder,
.widget select::placeholder,
.widget textarea::placeholder {
  opacity: 1;
  transition: all 0.2s;
}
.widget input.text:placeholder-shown:not(:focus):not(.no-label)::placeholder,
.widget input.captcha:placeholder-shown:not(:focus):not(.no-label)::placeholder,
.widget select:placeholder-shown:not(:focus):not(.no-label)::placeholder,
.widget textarea:placeholder-shown:not(:focus):not(.no-label)::placeholder {
  opacity: 0;
}
.widget input.text:focus,
.widget input.captcha:focus,
.widget select:focus,
.widget textarea:focus {
  outline: none;
  border-color: #DB2C3C;
}
.widget input.text:focus + label,
.widget input.captcha:focus + label,
.widget select:focus + label,
.widget textarea:focus + label {
  color: #B50717;
}
.widget input.text:placeholder-shown:not(:focus) + label, .widget input.text.no-placeholder:not(:focus) + label, .widget input.text.empty + label,
.widget input.captcha:placeholder-shown:not(:focus) + label,
.widget input.captcha.no-placeholder:not(:focus) + label,
.widget input.captcha.empty + label,
.widget select:placeholder-shown:not(:focus) + label,
.widget select.no-placeholder:not(:focus) + label,
.widget select.empty + label,
.widget textarea:placeholder-shown:not(:focus) + label,
.widget textarea.no-placeholder:not(:focus) + label,
.widget textarea.empty + label {
  top: 13px;
  color: #B0AFAF;
  font-size: inherit;
  pointer-events: none;
}
.widget input[type=number] {
  -moz-appearance: textfield;
}
.widget input[type=number]::-webkit-outer-spin-button, .widget input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.widget select {
  padding-right: 1em;
  background: url("../images/widget-select.svg") no-repeat right 16px center;
  background-size: 16px 9px;
}
.widget-submit {
  border: none;
}
.widget-submit button {
  display: block;
  width: 100%;
  height: 56px;
  padding: 16px 15px 14px;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  line-height: 15px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  background: #DB2C3C;
  border: none;
  cursor: pointer;
}
.widget-submit button:hover {
  background: #B50717;
}
.widget-explanation {
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;
}
.widget-explanation.note {
  margin-bottom: 3em !important;
  font-size: 15px;
  line-height: 24px;
}
.widget-explanation p {
  margin-top: 0;
}
.widget-explanation a {
  color: #B50717;
}
.widget-explanation a:hover {
  color: #DB2C3C;
}
.widget-explanation a:active {
  color: #2F2F2F;
}
.widget-counter {
  font-size: 14px;
  font-style: italic;
  padding-left: 15px;
}
.widget-captcha .captcha_text {
  order: 1;
  padding: 10px 21.5px 0;
  position: relative;
  font-size: 0.8em;
  border: 1.5px solid #B0AFAF;
  border-bottom: none;
}
.widget-captcha input.captcha {
  order: 2;
  padding-top: 8px !important;
  border-top: none !important;
}
.widget-captcha input.captcha:focus + .captcha_text {
  border-color: #DB2C3C;
}
.widget label > .mandatory,
.widget legend > .mandatory {
  color: #B50717;
  margin-left: 3px;
}
.widget p.error {
  order: 10;
  background: #DB2C3C;
  color: #fff;
  padding: 8px 23px 7px;
  position: relative;
  font-size: 0.8em;
  margin: 10px 0 0;
}
.widget p.error:before {
  position: absolute;
  top: -5px;
  left: 28px;
  width: 10px;
  height: 10px;
  background: #DB2C3C;
  transform: rotateZ(45deg);
  content: "";
}

.radio_container > legend,
.checkbox_container > legend {
  margin: 0 0 0.2em;
  padding: 0;
  font-weight: 700;
}
.radio_container > span,
.radio_container > fieldset > span,
.checkbox_container > span,
.checkbox_container > fieldset > span {
  display: block;
}
.radio_container fieldset > legend,
.checkbox_container fieldset > legend {
  margin: 0 0 0.2em;
  padding: 0;
  font-size: 0.9em;
  font-weight: 700;
}
.radio_container fieldset + fieldset,
.checkbox_container fieldset + fieldset {
  margin-top: 1em;
}
.radio_container.inline > span, .radio_container.inline > fieldset > span,
.checkbox_container.inline > span,
.checkbox_container.inline > fieldset > span {
  display: inline-block;
  margin-right: 1em;
}
.radio_container input[type=radio],
.radio_container input[type=checkbox],
.checkbox_container input[type=radio],
.checkbox_container input[type=checkbox] {
  position: absolute;
  opacity: 0;
}
.radio_container input[type=radio] + label,
.radio_container input[type=checkbox] + label,
.checkbox_container input[type=radio] + label,
.checkbox_container input[type=checkbox] + label {
  display: block;
  position: relative;
  padding: 0.1em 0 0.1em 1.5em;
  line-height: 1.2;
}
.radio_container input[type=radio] + label:before,
.radio_container input[type=checkbox] + label:before,
.checkbox_container input[type=radio] + label:before,
.checkbox_container input[type=checkbox] + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.3em;
  display: block;
  width: 1em;
  height: 1em;
  border: 1px solid #B0AFAF;
  border-radius: 3px;
}
.radio_container input[type=radio] + label:after,
.radio_container input[type=checkbox] + label:after,
.checkbox_container input[type=radio] + label:after,
.checkbox_container input[type=checkbox] + label:after {
  content: "";
  position: absolute;
  display: block;
}
.radio_container input[type=radio]:focus + label:before, .radio_container input[type=radio] + label:hover:before,
.radio_container input[type=checkbox]:focus + label:before,
.radio_container input[type=checkbox] + label:hover:before,
.checkbox_container input[type=radio]:focus + label:before,
.checkbox_container input[type=radio] + label:hover:before,
.checkbox_container input[type=checkbox]:focus + label:before,
.checkbox_container input[type=checkbox] + label:hover:before {
  border-color: #2F2F2F;
}
.radio_container input[type=radio]:disabled + label,
.radio_container input[type=checkbox]:disabled + label,
.checkbox_container input[type=radio]:disabled + label,
.checkbox_container input[type=checkbox]:disabled + label {
  color: #9B9B9B;
}
.radio_container input[type=radio]:disabled + label:before,
.radio_container input[type=checkbox]:disabled + label:before,
.checkbox_container input[type=radio]:disabled + label:before,
.checkbox_container input[type=checkbox]:disabled + label:before {
  border-color: #B0AFAF !important;
}

.radio_container input[type=radio] + label:before {
  border-radius: 50%;
}
.radio_container input[type=radio]:checked + label:after {
  left: 3px;
  top: calc(50% - 0.3em + 1px);
  width: calc(1em - 6px);
  height: calc(1em - 6px);
  background: #2F2F2F;
  border-radius: 50%;
}

.checkbox_container input[type=checkbox]:checked + label:before {
  background: #2F2F2F;
  border-color: #2F2F2F;
}
.checkbox_container input[type=checkbox]:checked + label:after {
  left: 0;
  top: 0.3em;
  width: 1em;
  height: 1em;
  background: url("../images/checkbox.svg") center center no-repeat;
  background-size: contain;
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

.ce_form hr {
  width: 100%;
  margin: 0 0 15px;
  border-top: 1px solid #D4D4D4;
  border-bottom: 0;
}

@media screen and (min-width: 767px) {
  .widget {
    margin-bottom: 23px;
  }
  .widget-explanation.note {
    margin-bottom: 5em;
  }
  fieldset.group-50-50, fieldset.group-30-70, fieldset.group-70-30, fieldset.group-33-33-33 {
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
  }
  fieldset.group-50-50 > .widget, fieldset.group-30-70 > .widget, fieldset.group-70-30 > .widget, fieldset.group-33-33-33 > .widget {
    margin-left: 15px;
    margin-right: 15px;
  }
  fieldset.group-50-50 > .widget-text, fieldset.group-30-70 > .widget-text, fieldset.group-70-30 > .widget-text, fieldset.group-33-33-33 > .widget-text {
    margin-left: 5px;
    margin-right: 5px;
  }
  fieldset.group-50-50 > .widget-text:first-child, fieldset.group-30-70 > .widget-text:first-child, fieldset.group-70-30 > .widget-text:first-child, fieldset.group-33-33-33 > .widget-text:first-child {
    margin-left: 15px;
  }
  fieldset.group-50-50 > .widget-text:last-child, fieldset.group-30-70 > .widget-text:last-child, fieldset.group-70-30 > .widget-text:last-child, fieldset.group-33-33-33 > .widget-text:last-child {
    margin-right: 15px;
  }
  fieldset.group-50-50 > .widget {
    width: 50%;
  }
  fieldset.group-30-70 > .widget:nth-child(odd) {
    width: 30%;
  }
  fieldset.group-30-70 > .widget:nth-child(even) {
    width: 70%;
  }
  fieldset.group-70-30 > .widget:nth-child(odd) {
    width: 70%;
  }
  fieldset.group-70-30 > .widget:nth-child(even) {
    width: 30%;
  }
  fieldset.group-33-33-33 > .widget {
    width: 33.33333333%;
  }
  .ce_form hr {
    margin-bottom: 25px;
  }
  .ce_form .formbody {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  .ce_form .formbody > .widget,
  .ce_form .formbody > fieldset {
    width: calc(50% - 20px);
    margin-left: 10px;
    margin-right: 10px;
  }
  .ce_form .formbody > .widget.full-width,
  .ce_form .formbody > fieldset.full-width {
    width: 100% !important;
  }
  .ce_form .formbody > .widget.break-after,
  .ce_form .formbody > fieldset.break-after {
    margin-right: 50% !important;
  }
  .ce_form .formbody > .widget.push-right,
  .ce_form .formbody > fieldset.push-right {
    margin-left: calc(50% + 5px) !important;
  }
  .ce_form .formbody > fieldset.group-50-50, .ce_form .formbody > fieldset.group-30-70, .ce_form .formbody > fieldset.group-70-30, .ce_form .formbody > fieldset.group-33-33-33 {
    width: calc(50% - 10px);
    margin-left: 5px;
    margin-right: 5px;
  }
  .ce_form .formbody > .widget-submit {
    width: 25%;
    margin-left: 75%;
  }
}
body.layout--overlay {
  overflow: hidden;
  position: fixed;
  width: 100vw;
}
@media screen and (min-width: 767px) {
  body {
    padding-top: 95px;
  }
  body.layout--overlay {
    position: static;
  }
}

.section-header {
  height: 50px;
  background: #fff;
  border-top: 4px solid #E1081C;
  transition: border-top-color 0.3s ease-in-out;
}
.section-header:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 50px;
  box-shadow: 0 0.5px 2px 0 #D4D4D4;
  pointer-events: none;
  z-index: 50;
}
@media screen and (max-width: 766px) {
  .layout--suchtindex .section-header:after {
    content: none;
  }
}
.section-header__logo {
  display: inline-block;
  margin: 10px 0 10px 25px;
}
.section-header__logo a {
  display: block;
  width: 190px;
  height: 25px;
  background: url("../../images/infodrog_logo_de.svg") left top no-repeat;
  background-size: contain;
  text-indent: -999em;
}
html[lang=fr] .section-header__logo a {
  background-image: url("../../images/infodrog_logo_fr.svg");
}
html[lang=it] .section-header__logo a {
  background-image: url("../../images/infodrog_logo_it.svg");
}
html[lang=en] .section-header__logo a {
  background-image: url("../../images/infodrog_logo_en.svg");
}
.layout--sticky .section-header__logo a {
  width: 65px;
  background-image: url("../../images/infodrog_logo.svg") !important;
}
.section-header__navtoggle {
  position: absolute;
  right: 10px;
  top: 6px;
  width: 42px;
  height: 42px;
  padding: 20px 10px 0;
}
.section-header__navtoggle span, .section-header__navtoggle span:before, .section-header__navtoggle span:after {
  cursor: pointer;
  border-radius: 2px;
  height: 1.5px;
  width: 22px;
  background: #B50717;
  position: absolute;
  display: block;
  content: "";
  transition: all 0.2s ease-in-out;
}
.section-header__navtoggle span:before {
  top: -6px;
}
.section-header__navtoggle span:after {
  bottom: -6px;
}
.layout--overlay .section-header__navtoggle span {
  background-color: transparent;
}
.layout--overlay .section-header__navtoggle span:before {
  top: 0;
  transform: rotate(45deg);
}
.layout--overlay .section-header__navtoggle span:after {
  top: 0;
  transform: rotate(-45deg);
}
.section-header__overlay {
  position: fixed;
  z-index: 50;
  left: 0;
  right: 0;
  bottom: 0;
  top: 50px;
  height: calc(100vh - 50px);
  height: calc(var(--vh, 1vh) * 100 - 50px);
  padding-bottom: 56px;
  background: #fff;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  opacity: 0;
}
.layout--overlay .section-header__overlay {
  opacity: 1;
  pointer-events: initial;
}
.section-header__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  max-width: 600px;
  margin: 0 auto;
  padding: 70px 0;
  font-size: 21px;
  line-height: 1.5;
  text-align: center;
}
.section-header__navigation a {
  color: #B50717;
  display: block;
  padding: 25px;
  font-weight: 400;
  text-transform: uppercase;
}
.section-header__navigation a:hover {
  color: #DB2C3C;
}
.section-header__navigation a:active {
  color: #2F2F2F;
}
.section-header__languages ul {
  display: flex;
  justify-content: center;
}
.section-header__languages a {
  color: #B50717;
  margin: 0 10px;
  padding: 10px;
}
.section-header__languages a:hover {
  color: #DB2C3C;
}
.section-header__languages a:active {
  color: #2F2F2F;
}

@media screen and (min-width: 767px) {
  .section-header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 95px;
    z-index: 100;
    border-top: 8px solid #E1081C;
  }
  .section-header:after {
    height: 77px;
    box-shadow: 0 1px 2px 0 #D4D4D4;
  }
  .section-header__logo {
    margin: 16px 0 0 30px;
  }
  .section-header__logo a {
    width: 300px;
    height: 40px;
  }
  .section-header__navtoggle {
    top: 17px;
    right: 20px;
  }
  .section-header__overlay {
    top: 95px;
    height: calc(100vh - 95px);
    height: calc(var(--vh, 1vh) * 100 - 95px);
  }
  .section-header__navigation {
    font-size: 24px;
  }
}
@media screen and (min-width: 1220px) {
  .section-header__inside {
    position: relative;
    max-width: 1260px;
    margin: 0 auto;
  }
}
.section-subheader {
  display: block;
  position: relative;
  overflow-x: hidden;
}
.section-subheader__inside {
  display: flex;
  justify-content: space-between;
}
.section-subheader__breadcrumb {
  margin: 15px 0 15px 25px;
  color: #9B9B9B;
  font-size: 8px;
  line-height: 11px;
}
.section-subheader__breadcrumb li {
  display: inline;
  text-transform: uppercase;
}
.section-subheader__breadcrumb li:after {
  content: "  / ";
}
.section-subheader__breadcrumb li.last:after {
  content: none;
}
.section-subheader__breadcrumb li strong {
  font-weight: 400;
}
.section-subheader__breadcrumb li a:hover {
  color: #DB2C3C;
}
.section-subheader__breadcrumb li a:active {
  color: #2F2F2F;
}
.section-subheader__breadcrumb li.first a {
  display: inline-block;
  width: 11px;
  height: 11px;
  background: url("../../images/home.svg") 0 0 no-repeat;
  line-height: 1.8;
  text-indent: -999em;
}
.section-subheader__breadcrumb li.first a:hover {
  background-position: -11px 0;
}
.section-subheader__breadcrumb li.first a:active {
  background-position: -22px 0;
}
.section-subheader__links {
  margin: 9px;
}
.section-subheader__links ul {
  display: flex;
  justify-content: center;
}
.section-subheader__link a {
  display: flex;
  margin-top: -2px;
  padding: 5px;
  text-indent: -999em;
  white-space: nowrap;
}
.section-subheader__link a:hover {
  color: #DB2C3C;
}
.section-subheader__link a:active {
  color: #2F2F2F;
}
.section-subheader__link--video a:after {
  position: relative;
  flex-shrink: 0;
  display: block;
  width: 20px;
  height: 21.6px;
  background: url("../images/icons.svg") 0px -172.8px no-repeat;
  background-size: 80px 324px;
  content: "";
  margin-right: 12px;
  cursor: pointer;
}
.section-subheader__link--video a:hover:after {
  background-position: -40px -172.8px;
}
.section-subheader__link--video a:active:after {
  background-position: -60px -172.8px;
}
.section-subheader__link--share a:after {
  position: relative;
  flex-shrink: 0;
  display: block;
  width: 20px;
  height: 21.6px;
  background: url("../images/icons.svg") 0px -194.4px no-repeat;
  background-size: 80px 324px;
  content: "";
  margin-right: 12px;
  cursor: pointer;
}
.section-subheader__link--share a:hover:after {
  background-position: -40px -194.4px;
}
.section-subheader__link--share a:active:after {
  background-position: -60px -194.4px;
}
.section-subheader__link--watchlist a:after {
  position: relative;
  flex-shrink: 0;
  display: block;
  width: 20px;
  height: 21.6px;
  background: url("../images/icons.svg") 0px -216px no-repeat;
  background-size: 80px 324px;
  content: "";
  margin-right: 12px;
  cursor: pointer;
}
.section-subheader__link--watchlist a:hover:after {
  background-position: -40px -216px;
}
.section-subheader__link--watchlist a:active:after {
  background-position: -60px -216px;
}
.section-subheader__link--print a:after {
  position: relative;
  flex-shrink: 0;
  display: block;
  width: 20px;
  height: 21.6px;
  background: url("../images/icons.svg") 0px -237.6px no-repeat;
  background-size: 80px 324px;
  content: "";
  margin-right: 12px;
  cursor: pointer;
}
.section-subheader__link--print a:hover:after {
  background-position: -40px -237.6px;
}
.section-subheader__link--print a:active:after {
  background-position: -60px -237.6px;
}
.section-subheader__link--print {
  display: none;
}
.layout--suchtindex .section-subheader__link a {
  padding: 2px 20px;
  text-indent: 0;
}
.layout--suchtindex .section-subheader__link a:after {
  content: none;
}
.layout--suchtindex .section-subheader__link--watchlist {
  position: relative;
}
.layout--suchtindex .section-subheader__link--video a:before {
  position: relative;
  flex-shrink: 0;
  display: block;
  width: 25px;
  height: 27px;
  background: url("../images/icons.svg") 0px -216px no-repeat;
  background-size: 100px 405px;
  content: "";
  margin-right: 15px;
  cursor: pointer;
  margin-right: 10px;
}
.layout--suchtindex .section-subheader__link--video a:hover:before {
  background-position: -50px -216px;
}
.layout--suchtindex .section-subheader__link--video a:active:before {
  background-position: -75px -216px;
}
.layout--suchtindex .section-subheader__link--share a:before {
  position: relative;
  flex-shrink: 0;
  display: block;
  width: 25px;
  height: 27px;
  background: url("../images/icons.svg") 0px -243px no-repeat;
  background-size: 100px 405px;
  content: "";
  margin-right: 15px;
  cursor: pointer;
  margin-right: 10px;
}
.layout--suchtindex .section-subheader__link--share a:hover:before {
  background-position: -50px -243px;
}
.layout--suchtindex .section-subheader__link--share a:active:before {
  background-position: -75px -243px;
}
.layout--suchtindex .section-subheader__link--watchlist a:before {
  position: relative;
  flex-shrink: 0;
  display: block;
  width: 25px;
  height: 27px;
  background: url("../images/icons.svg") 0px -270px no-repeat;
  background-size: 100px 405px;
  content: "";
  margin-right: 15px;
  cursor: pointer;
  margin-right: 10px;
}
.layout--suchtindex .section-subheader__link--watchlist a:hover:before {
  background-position: -50px -270px;
}
.layout--suchtindex .section-subheader__link--watchlist a:active:before {
  background-position: -75px -270px;
}
.layout--suchtindex .section-subheader__link--print a:before {
  position: relative;
  flex-shrink: 0;
  display: block;
  width: 25px;
  height: 27px;
  background: url("../images/icons.svg") 0px -297px no-repeat;
  background-size: 100px 405px;
  content: "";
  margin-right: 15px;
  cursor: pointer;
  margin-right: 10px;
}
.layout--suchtindex .section-subheader__link--print a:hover:before {
  background-position: -50px -297px;
}
.layout--suchtindex .section-subheader__link--print a:active:before {
  background-position: -75px -297px;
}
.section-subheader__watchlist-count {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 16px;
  height: 16px;
  margin-left: 3px;
  padding: 0 3px;
  font-size: 12px;
  line-height: 15px;
  text-indent: 0;
  color: #fff;
  font-weight: 500;
  background: #DB2C3C;
  border-radius: 20px;
  z-index: 1;
}
.layout--sticky .section-subheader__watchlist-count, .section-subheader__links--sticky .section-subheader__watchlist-count {
  position: absolute;
  right: 0;
}
.section-subheader__watchlist-count:empty {
  display: none;
}
.section-subheader__watchlist-count--updated {
  animation: 0.2s ease-in-out watchlist-updated;
}
@keyframes watchlist-updated {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (min-width: 767px) {
  .section-subheader__breadcrumb {
    margin: 20px 30px 5px;
    font-size: 14px;
    line-height: 27px;
  }
  .section-subheader__breadcrumb li.first a {
    width: 15px;
    height: 15px;
    line-height: 1;
    background-size: 45px 15px;
  }
  .section-subheader__breadcrumb li.first a:hover {
    background-position: -15px 0;
  }
  .section-subheader__breadcrumb li.first a:active {
    background-position: -30px 0;
  }
  .section-subheader__links {
    margin: 20px 20px 5px;
  }
  .layout--suchtindex .section-subheader__links {
    animation: 0.3s ease-in-out unsticky;
  }
  .layout--sticky .section-subheader__links, .section-subheader__links--sticky {
    position: fixed;
    top: 14px;
    right: 60px;
    z-index: 100;
    animation: 0.3s ease-in-out sticky;
  }
  .layout--suchtindex .section-subheader__link a,
  .section-subheader__link a {
    padding: 0;
    margin-left: 25px;
    text-indent: 0;
  }
  .layout--suchtindex .section-subheader__link a:after,
  .section-subheader__link a:after {
    content: none;
  }
  .section-subheader__link--print {
    display: initial;
  }
  .section-subheader__link--video a:before {
    position: relative;
    flex-shrink: 0;
    display: block;
    width: 25px;
    height: 27px;
    background: url("../images/icons.svg") 0px -216px no-repeat;
    background-size: 100px 405px;
    content: "";
    margin-right: 15px;
    cursor: pointer;
    margin-right: 10px;
  }
  .section-subheader__link--video a:hover:before {
    background-position: -50px -216px;
  }
  .section-subheader__link--video a:active:before {
    background-position: -75px -216px;
  }
  .section-subheader__link--share a:before {
    position: relative;
    flex-shrink: 0;
    display: block;
    width: 25px;
    height: 27px;
    background: url("../images/icons.svg") 0px -243px no-repeat;
    background-size: 100px 405px;
    content: "";
    margin-right: 15px;
    cursor: pointer;
    margin-right: 10px;
  }
  .section-subheader__link--share a:hover:before {
    background-position: -50px -243px;
  }
  .section-subheader__link--share a:active:before {
    background-position: -75px -243px;
  }
  .section-subheader__link--watchlist a:before {
    position: relative;
    flex-shrink: 0;
    display: block;
    width: 25px;
    height: 27px;
    background: url("../images/icons.svg") 0px -270px no-repeat;
    background-size: 100px 405px;
    content: "";
    margin-right: 15px;
    cursor: pointer;
    margin-right: 10px;
  }
  .section-subheader__link--watchlist a:hover:before {
    background-position: -50px -270px;
  }
  .section-subheader__link--watchlist a:active:before {
    background-position: -75px -270px;
  }
  .section-subheader__link--print a:before {
    position: relative;
    flex-shrink: 0;
    display: block;
    width: 25px;
    height: 27px;
    background: url("../images/icons.svg") 0px -297px no-repeat;
    background-size: 100px 405px;
    content: "";
    margin-right: 15px;
    cursor: pointer;
    margin-right: 10px;
  }
  .section-subheader__link--print a:hover:before {
    background-position: -50px -297px;
  }
  .section-subheader__link--print a:active:before {
    background-position: -75px -297px;
  }
  .layout--sticky .section-subheader__link a, .section-subheader__links--sticky .section-subheader__link a {
    margin-left: 10px;
    text-indent: -999em;
  }
  .layout--sticky .section-subheader__link a:before, .section-subheader__links--sticky .section-subheader__link a:before {
    content: none;
  }
  .layout--sticky .section-subheader__link--video a:after, .section-subheader__links--sticky .section-subheader__link--video a:after {
    position: relative;
    flex-shrink: 0;
    display: block;
    width: 25px;
    height: 27px;
    background: url("../images/icons.svg") 0px -216px no-repeat;
    background-size: 100px 405px;
    content: "";
    margin-right: 15px;
    cursor: pointer;
    margin-right: 10px;
  }
  .layout--sticky .section-subheader__link--video a:hover:after, .section-subheader__links--sticky .section-subheader__link--video a:hover:after {
    background-position: -50px -216px;
  }
  .layout--sticky .section-subheader__link--video a:active:after, .section-subheader__links--sticky .section-subheader__link--video a:active:after {
    background-position: -75px -216px;
  }
  .layout--sticky .section-subheader__link--share a:after, .section-subheader__links--sticky .section-subheader__link--share a:after {
    position: relative;
    flex-shrink: 0;
    display: block;
    width: 25px;
    height: 27px;
    background: url("../images/icons.svg") 0px -243px no-repeat;
    background-size: 100px 405px;
    content: "";
    margin-right: 15px;
    cursor: pointer;
    margin-right: 10px;
  }
  .layout--sticky .section-subheader__link--share a:hover:after, .section-subheader__links--sticky .section-subheader__link--share a:hover:after {
    background-position: -50px -243px;
  }
  .layout--sticky .section-subheader__link--share a:active:after, .section-subheader__links--sticky .section-subheader__link--share a:active:after {
    background-position: -75px -243px;
  }
  .layout--sticky .section-subheader__link--watchlist a:after, .section-subheader__links--sticky .section-subheader__link--watchlist a:after {
    position: relative;
    flex-shrink: 0;
    display: block;
    width: 25px;
    height: 27px;
    background: url("../images/icons.svg") 0px -270px no-repeat;
    background-size: 100px 405px;
    content: "";
    margin-right: 15px;
    cursor: pointer;
    margin-right: 10px;
  }
  .layout--sticky .section-subheader__link--watchlist a:hover:after, .section-subheader__links--sticky .section-subheader__link--watchlist a:hover:after {
    background-position: -50px -270px;
  }
  .layout--sticky .section-subheader__link--watchlist a:active:after, .section-subheader__links--sticky .section-subheader__link--watchlist a:active:after {
    background-position: -75px -270px;
  }
  .layout--sticky .section-subheader__link--print a:after, .section-subheader__links--sticky .section-subheader__link--print a:after {
    position: relative;
    flex-shrink: 0;
    display: block;
    width: 25px;
    height: 27px;
    background: url("../images/icons.svg") 0px -297px no-repeat;
    background-size: 100px 405px;
    content: "";
    margin-right: 15px;
    cursor: pointer;
    margin-right: 10px;
  }
  .layout--sticky .section-subheader__link--print a:hover:after, .section-subheader__links--sticky .section-subheader__link--print a:hover:after {
    background-position: -50px -297px;
  }
  .layout--sticky .section-subheader__link--print a:active:after, .section-subheader__links--sticky .section-subheader__link--print a:active:after {
    background-position: -75px -297px;
  }
}
@media screen and (min-width: 1220px) {
  .section-subheader {
    max-width: 1450px;
    margin: 0 auto;
  }
  .layout--suchtindex .section-subheader {
    display: block;
  }
  .section-subheader__inside {
    max-width: 1260px;
    margin: 0 auto;
  }
  .section-subheader__breadcrumb {
    margin: 50px 100px 5px 30px;
  }
  .section-subheader__links {
    margin: 50px 30px 5px 100px;
  }
  .layout--sticky .section-subheader__links, .section-subheader__links--sticky {
    right: calc((100vw - 1220px) / 2 + 60px);
    margin: 20px;
  }
  .section-subheader__link a {
    margin-left: 30px !important;
  }
}
.section-service {
  margin-top: 40px;
  padding: 70px 0 0;
  border-top: 1px solid #EAEAEA;
}
.layout--suchtindex .section-service {
  border-top: none;
}
.section-service .mod_article__inside {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto auto;
  padding: 0 30px;
}
.section-service .mod_article__inside .ce_image {
  grid-row: 3;
}
.section-service .mod_article__inside .ce_text {
  grid-row: 1;
}
.section-service .mod_article__inside .ce_hyperlink {
  grid-row: 2;
}
.section-service .ce_image {
  margin: 0 -30px;
}
.section-service h2 {
  margin: 0;
  font-size: 13px;
  line-height: 15px;
  text-transform: uppercase;
  color: #2F2F2F;
}
.section-service p {
  margin: 35px 0 0;
  font-size: 23px;
  line-height: 33px;
  color: #B50717;
}
.section-service p strong {
  font-weight: 500;
}
.section-service .ce_hyperlink {
  margin-top: 35px !important;
}
.section-service .ce_hyperlink a {
  position: relative;
  display: inline-block;
  min-height: 25px;
  padding-left: 45px;
  color: #B50717;
  background: none;
  border: none;
  cursor: pointer;
  color: #595959;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.section-service .ce_hyperlink a:before {
  content: "";
  position: absolute;
  width: 24px;
  height: 25px;
  left: 0px;
  top: 3px;
  background: url("../images/link-forward--infodrog.svg") 0 0/125px 25px no-repeat;
}
.section-service .ce_hyperlink a:hover {
  color: #DB2C3C;
}
.section-service .ce_hyperlink a:hover:before {
  background-position: -25px 0;
}
.section-service .ce_hyperlink a:active {
  color: #2F2F2F;
}
.section-service .ce_hyperlink a:active:before {
  background-position: -50px 0;
}

@media screen and (min-width: 767px) {
  .section-service {
    padding: 100px 30px 100px;
  }
  .section-service .mod_article__inside {
    grid-template-columns: 48% 12% 40%;
    grid-template-rows: auto 35px auto;
  }
  .section-service .mod_article__inside .ce_image {
    grid-column: 1;
    grid-row: 1/4;
  }
  .section-service .mod_article__inside .ce_text {
    grid-column: 3;
    grid-row: 1;
    align-self: end;
  }
  .section-service .mod_article__inside .ce_hyperlink {
    grid-column: 3;
    grid-row: 3;
    align-self: start;
  }
  .section-service .ce_image {
    margin: 0;
  }
  .section-service h2 {
    font-size: 17px;
    line-height: 20px;
  }
  .section-service p {
    margin-top: 55px;
    font-size: 34px;
    line-height: 44px;
  }
  .section-service .ce_hyperlink {
    margin-top: 0 !important;
  }
}
@media screen and (min-width: 1220px) {
  .section-service .mod_article__inside {
    max-width: 1260px;
    margin: 0 auto;
  }
}
.section-prefooter {
  color: #EBEBEB;
  font-size: 13px;
  line-height: 19px;
  background: #DB2C3C;
}
.section-prefooter__inside {
  position: relative;
  padding: 50px 0;
}
.section-prefooter__top {
  position: absolute;
  top: 16px;
  right: 20px;
  width: 37px;
  height: 30px;
  text-indent: -999em;
  background: #EB4958 url("../../images/link-top.svg") center center no-repeat;
}
.section-prefooter__address {
  padding: 0 20px;
}
.section-prefooter__address h1 {
  margin: 0;
  color: #F9F9F9;
  font-size: 18px;
  font-weight: 700;
}
.section-prefooter__address a:hover {
  text-decoration: underline;
}
.section-prefooter__social {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  margin-right: 6px;
  text-indent: -999em;
  background: #fff;
}
.section-prefooter__social--linkedin {
  background: url("../../images/linkedin.svg") 0 0/contain no-repeat;
}
.section-prefooter__about, .section-prefooter__quicklinks {
  margin-top: 45px;
}
.section-prefooter__about h2, .section-prefooter__quicklinks h2 {
  margin: 0;
  padding: 0 20px 11px;
  font-size: 14px;
  text-transform: uppercase;
}
.section-prefooter__about ul, .section-prefooter__quicklinks ul {
  border-top: 1px solid #D4D4D4;
}
.section-prefooter__about a, .section-prefooter__quicklinks a {
  position: relative;
  display: block;
  padding: 12px 60px 11px 20px;
  border-bottom: 1px solid #D4D4D4;
  transition: padding-left 0.3s linear;
}
.section-prefooter__about a:after, .section-prefooter__quicklinks a:after {
  position: absolute;
  right: 40px;
  top: 10px;
  width: 25px;
  height: 25px;
  background: url("../../images/link-forward--infodrog.svg") -100px 0 no-repeat;
  opacity: 0;
  content: "";
  transition: all 0.3s linear;
}
.section-prefooter__about a:hover, .section-prefooter__quicklinks a:hover {
  opacity: 0.8;
}
.section-prefooter__about a:hover:after, .section-prefooter__quicklinks a:hover:after {
  right: 20px;
  opacity: 1;
}

@media screen and (min-width: 767px) {
  .section-prefooter {
    overflow: hidden;
    font-size: 17px;
    line-height: 23px;
  }
  .section-prefooter__inside {
    padding: 75px 0 0;
  }
  .section-prefooter__address {
    float: left;
    width: 50%;
    padding: 0 30px;
  }
  .section-prefooter__address h1 {
    font-size: 20px;
  }
  .section-prefooter__about, .section-prefooter__quicklinks {
    width: 50%;
    margin: 0 0 45px 50%;
    padding: 0 30px;
  }
  .section-prefooter__about h2, .section-prefooter__quicklinks h2 {
    padding-left: 0;
    padding-right: 0;
    font-size: 16px;
  }
  .section-prefooter__about a, .section-prefooter__quicklinks a {
    padding-left: 0;
    padding-right: 0;
  }
  .section-prefooter__about a:after, .section-prefooter__quicklinks a:after {
    top: 13px;
  }
}
@media screen and (min-width: 1220px) {
  .section-prefooter__inside {
    max-width: 1260px;
    margin: 0 auto;
    padding-bottom: 80px;
  }
  .section-prefooter__inside:after {
    display: table;
    clear: both;
    content: "";
  }
  .section-prefooter__top {
    display: none;
  }
  .section-prefooter__address {
    width: 40%;
  }
  .section-prefooter__about, .section-prefooter__quicklinks {
    float: left;
    width: 28%;
    margin-left: 0;
    margin-bottom: 0;
  }
}
.section-footer {
  padding: 27px 0 24px;
  color: #EBEBEB;
  font-size: 13px;
  line-height: 19px;
  background: #B50717;
}
.section-footer__inside {
  position: relative;
  padding: 0 20px;
}
.section-footer__navigation li {
  display: inline;
  padding-right: 20px;
}
.section-footer__navigation a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 767px) {
  .section-footer {
    padding: 40px 0 37px;
    font-size: 15px;
  }
  .section-footer__inside {
    padding: 0 30px;
  }
}
@media screen and (min-width: 1220px) {
  .section-footer__inside {
    max-width: 1260px;
    margin: 0 auto;
  }
}
.layout--content .section-main {
  margin-bottom: 50px;
}
.layout--content .section-main__inside {
  padding: 0 !important;
}
.layout--content .section-main .mod_article__inside {
  position: relative;
  padding: 12px 25px 25px;
}
@media screen and (min-width: 767px) {
  .layout--content .section-main .mod_article__inside {
    padding: 25px 30px 50px;
  }
}
@media screen and (min-width: 1220px) {
  .layout--content .section-main {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1220px) {
  .layout--content.layout--narrow .section-main .mod_article__inside {
    max-width: 860px;
  }
}
.section-main [class="^ce_"] h2,
.section-main .admin-form h2,
.section-main .location-details h2 {
  font-size: 21px;
}
.section-main [class="^ce_"] h3,
.section-main [class="^ce_"] #ctrl_offering > legend,
.section-main .admin-form h3,
.section-main .admin-form #ctrl_offering > legend,
.section-main .location-details h3,
.section-main .location-details #ctrl_offering > legend {
  font-size: 18px;
  font-weight: 500;
}
.section-main .ce_headline,
.section-main .ce_text__headline {
  margin: 35px 0 1em;
}
.section-main .page-headline,
.section-main .ce_text--lead .ce_text__headline {
  font-size: 23px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.25px;
  color: #2F2F2F;
}
.section-main .ce_text {
  margin-top: 35px;
  margin-bottom: 35px;
}
.section-main .ce_text--lead .ce_text__headline {
  margin: 0 0 25px;
}
.section-main .ce_text ul,
.section-main .ce_list ul,
.section-main .location-details__text ul {
  margin: 0.5em 0 1em;
  padding: 0;
  list-style-type: none;
}
.section-main .ce_text p + ul,
.section-main .ce_list p + ul,
.section-main .location-details__text p + ul {
  margin-top: -0.5em;
}
.section-main .ce_text ul li,
.section-main .ce_list ul li,
.section-main .location-details__text ul li {
  position: relative;
  margin: 0;
  padding: 0 0 0 30px;
}
.section-main .ce_text ul li:before,
.section-main .ce_list ul li:before,
.section-main .location-details__text ul li:before {
  position: absolute;
  left: 0;
  top: -2px;
  color: #B50717;
  content: "–";
}
.section-main .ce_table a,
.section-main p a {
  color: #B50717;
}
.section-main .ce_table a:hover,
.section-main p a:hover {
  color: #DB2C3C;
}
.section-main .ce_table a:active,
.section-main p a:active {
  color: #2F2F2F;
}
.section-main p.empty {
  font-style: italic;
}
.section-main .mod_login p.error,
.section-main .mod_lostPasswordNotificationCenter p.error {
  background: #DB2C3C;
  color: #fff;
  padding: 8px 23px 7px;
  position: relative;
  font-size: 0.8em;
  margin-bottom: 2em;
}
.section-main .mod_registrationNotificationCenter p.confirm {
  background: rgba(146, 219, 44, 0.2509803922);
  border: 2px solid #92db2c;
  padding: 8px 23px 7px;
  position: relative;
  font-size: 0.8em;
}

@media screen and (min-width: 767px) {
  .section-main [class="^ce_"] h2,
  .section-main .admin-form h2,
  .section-main .location-details h2 {
    font-size: 26px;
  }
  .section-main [class="^ce_"] h3,
  .section-main [class="^ce_"] #ctrl_offering > legend,
  .section-main .admin-form h3,
  .section-main .admin-form #ctrl_offering > legend,
  .section-main .location-details h3,
  .section-main .location-details #ctrl_offering > legend {
    font-size: 22px;
  }
  .section-main .ce_text--lead {
    font-size: 19px;
    line-height: 29px;
  }
  .section-main .ce_headline,
  .section-main .ce_text__headline {
    margin-top: 50px;
  }
  .section-main h2 + .ce_headline,
  .section-main h2 + .ce_text > .ce_text__headline {
    margin-top: 0;
  }
  .section-main .page-headline,
  .section-main .ce_text--lead .ce_text__headline {
    font-size: 32px;
    line-height: 44px;
    letter-spacing: 0.34px;
    margin: 35px 0 40px;
  }
}
.si-location {
  position: relative;
  display: flex;
  padding: 20px 0;
  font-size: 14px;
  line-height: 20px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid #EAEAEA;
}
.si-location--highlight:before {
  content: "";
  position: absolute;
  top: -1px;
  right: -8px;
  bottom: 0;
  left: -15px;
  border: 1px solid #9B9B9B;
  pointer-events: none;
}
.si-location__about {
  width: 200px;
  flex-grow: 1;
  padding-right: 15px;
}
.si-location__about--list {
  width: 40%;
  flex-shrink: 0;
  flex-grow: unset;
}
.si-location__name {
  font-size: 15px;
  color: #B50717;
  font-weight: 500;
}
.si-location--highlight .si-location__name, .si-location__link:hover .si-location__name {
  color: #DB2C3C;
}
.si-location__link:active .si-location__name {
  color: #2F2F2F;
}
.si-location__unpublished {
  font-size: 12px;
  margin-right: 5px;
  padding: 1px 10px;
  color: #fff;
  text-transform: uppercase;
  background: #2F2F2F;
  border-radius: 3px;
  cursor: help;
}
.si-location__address {
  margin-top: 4px;
  color: #808080;
}
.si-location__contact {
  display: flex;
  margin-top: 15px;
}
.si-location__contact-link {
  display: block;
  margin-right: 8px;
  line-height: 27px;
  color: #808080;
}
.si-location__contact-link--website:hover, .si-location__contact-link--email:hover, .si-location__contact-link--chat:hover {
  color: #DB2C3C;
}
.si-location__contact-link--website:active, .si-location__contact-link--email:active, .si-location__contact-link--chat:active {
  color: #2F2F2F;
}
.si-location__contact--collapsed {
  align-items: center;
}
.si-location__contact--collapsed .si-location__contact-link--website {
  position: relative;
  flex-shrink: 0;
  display: block;
  width: 25px;
  height: 27px;
  background: url("../images/icons.svg") 0px 0px no-repeat;
  background-size: 100px 405px;
  border: none;
  text-indent: -999em;
  cursor: pointer;
}
.si-location__contact--collapsed .si-location__contact-link--website:hover {
  background-position: -50px 0px;
}
.si-location__contact--collapsed .si-location__contact-link--website:active {
  background-position: -75px 0px;
}
.si-location__contact--collapsed .si-location__contact-link--email {
  position: relative;
  flex-shrink: 0;
  display: block;
  width: 25px;
  height: 27px;
  background: url("../images/icons.svg") 0px -27px no-repeat;
  background-size: 100px 405px;
  border: none;
  text-indent: -999em;
  cursor: pointer;
}
.si-location__contact--collapsed .si-location__contact-link--email:hover {
  background-position: -50px -27px;
}
.si-location__contact--collapsed .si-location__contact-link--email:active {
  background-position: -75px -27px;
}
.si-location__contact--collapsed .si-location__contact-link--chat {
  position: relative;
  flex-shrink: 0;
  display: block;
  width: 25px;
  height: 27px;
  background: url("../images/icons.svg") 0px -54px no-repeat;
  background-size: 100px 405px;
  border: none;
  text-indent: -999em;
  cursor: pointer;
}
.si-location__contact--collapsed .si-location__contact-link--chat:hover {
  background-position: -50px -54px;
}
.si-location__contact--collapsed .si-location__contact-link--chat:active {
  background-position: -75px -54px;
}
.si-location__contact--collapsed .si-location__contact-link--phone {
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.si-location__contact--expanded {
  flex-direction: column;
}
.si-location__contact--expanded .si-location__contact-link {
  display: flex;
  align-items: center;
  margin: 8px 0;
}
.si-location__contact--expanded .si-location__contact-link--website:before {
  position: relative;
  flex-shrink: 0;
  display: block;
  width: 25px;
  height: 27px;
  background: url("../images/icons.svg") 0px 0px no-repeat;
  background-size: 100px 405px;
  content: "";
  margin-right: 15px;
  cursor: pointer;
}
.si-location__contact--expanded .si-location__contact-link--website:hover:before {
  background-position: -50px 0px;
}
.si-location__contact--expanded .si-location__contact-link--website:active:before {
  background-position: -75px 0px;
}
.si-location__contact--expanded .si-location__contact-link--email:before {
  position: relative;
  flex-shrink: 0;
  display: block;
  width: 25px;
  height: 27px;
  background: url("../images/icons.svg") 0px -27px no-repeat;
  background-size: 100px 405px;
  content: "";
  margin-right: 15px;
  cursor: pointer;
}
.si-location__contact--expanded .si-location__contact-link--email:hover:before {
  background-position: -50px -27px;
}
.si-location__contact--expanded .si-location__contact-link--email:active:before {
  background-position: -75px -27px;
}
.si-location__contact--expanded .si-location__contact-link--phone:before {
  position: relative;
  flex-shrink: 0;
  display: block;
  width: 25px;
  height: 27px;
  background: url("../images/icons.svg") 0px -108px no-repeat;
  background-size: 100px 405px;
  content: "";
  margin-right: 15px;
}
.si-location__offering {
  display: none;
}
.si-location__actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  padding-left: 5px;
}
.si-location__watchlist {
  position: relative;
  flex-shrink: 0;
  display: block;
  width: 25px;
  height: 27px;
  background: url("../images/icons.svg") -25px -270px no-repeat;
  background-size: 100px 405px;
  border: none;
  text-indent: -999em;
  cursor: pointer;
}
.si-location__watchlist:hover {
  background-position: -50px -270px;
}
.si-location__watchlist:active {
  background-position: -75px -270px;
}
.si-location__watchlist--active {
  background-position: -75px -270px !important;
}
.si-location__admin {
  position: relative;
  flex-shrink: 0;
  display: block;
  width: 25px;
  height: 27px;
  background: url("../images/icons.svg") 0px -324px no-repeat;
  background-size: 100px 405px;
  border: none;
  text-indent: -999em;
  cursor: pointer;
}
.si-location__admin:hover {
  background-position: -50px -324px;
}
.si-location__admin:active {
  background-position: -75px -324px;
}
.si-location__details {
  position: relative;
  flex-shrink: 0;
  display: block;
  width: 25px;
  height: 27px;
  background: url("../images/icons.svg") 0px -189px no-repeat;
  background-size: 100px 405px;
  border: none;
  text-indent: -999em;
  cursor: pointer;
}
.si-location__details:hover {
  background-position: -50px -189px;
}
.si-location__details:active {
  background-position: -75px -189px;
}

@media screen and (min-width: 767px) {
  .si-location {
    margin-bottom: 8px;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid #EAEAEA;
  }
  .si-location--list {
    padding: 20px 26px 20px 30px;
    border: 1px solid #D4D4D4;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.0784313725);
  }
  .si-location--highlight {
    border: 1px solid #9B9B9B;
  }
  .si-location--highlight:before {
    content: none;
  }
  .si-location__about {
    width: 40%;
  }
  .si-location__address {
    margin-top: 7px;
  }
  .si-location__contact {
    margin-top: 25px;
  }
  .si-location__offering {
    display: block;
    flex-grow: 1;
    padding: 0 50px 0 15px;
    width: calc(60% - 30px);
  }
  .si-location__offering p {
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
  }
}
@media screen and (min-width: 1220px) {
  .si-location {
    margin-left: 0;
    font-size: 16px;
    line-height: 22px;
  }
  .si-location__name {
    font-size: 16px;
  }
}
.location-details h3 {
  margin: 30px 0 10px;
}
.location-details__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
}
.location-details__name {
  margin: 0;
  font-size: 22px;
  line-height: 1.4;
  font-weight: 300;
}
.location-details__name strong {
  color: #DB2C3C;
  font-weight: 300;
}
.location-details__section {
  margin-top: 30px;
  padding-top: 5px;
  border-top: 1px solid #eaeaea;
}
.location-details__section-margin {
  margin-top: 30px !important;
}
.location-details__map {
  margin: 30px -25px 0;
}
.location-details__map > div {
  height: 280px;
}
.location-details__address {
  display: flex;
  min-height: 27px;
  margin-bottom: 7px;
  line-height: 25px;
}
.location-details__address:before {
  position: relative;
  flex-shrink: 0;
  display: block;
  width: 25px;
  height: 27px;
  background: url("../images/icons.svg") -25px -135px no-repeat;
  background-size: 100px 405px;
  content: "";
  margin-right: 15px;
}
.location-details__links {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.location-details__links li {
  margin: 0;
  padding: 0;
}
.location-details__link {
  display: flex;
  margin: 4px 0 !important;
  line-height: 25px;
  overflow-wrap: anywhere;
}
.location-details__link--email:before {
  position: relative;
  flex-shrink: 0;
  display: block;
  width: 25px;
  height: 27px;
  background: url("../images/icons.svg") -25px -27px no-repeat;
  background-size: 100px 405px;
  content: "";
  margin-right: 15px;
}
.location-details__link--phone:before {
  position: relative;
  flex-shrink: 0;
  display: block;
  width: 25px;
  height: 27px;
  background: url("../images/icons.svg") -25px -108px no-repeat;
  background-size: 100px 405px;
  content: "";
  margin-right: 15px;
}
.location-details__link--fax:before {
  position: relative;
  flex-shrink: 0;
  display: block;
  width: 25px;
  height: 27px;
  background: url("../images/icons.svg") -25px -81px no-repeat;
  background-size: 100px 405px;
  content: "";
  margin-right: 15px;
}
.location-details__link--website:before {
  position: relative;
  flex-shrink: 0;
  display: block;
  width: 25px;
  height: 27px;
  background: url("../images/icons.svg") -25px 0px no-repeat;
  background-size: 100px 405px;
  content: "";
  margin-right: 15px;
}
.location-details__link--chat:before {
  position: relative;
  flex-shrink: 0;
  display: block;
  width: 25px;
  height: 27px;
  background: url("../images/icons.svg") -25px -54px no-repeat;
  background-size: 100px 405px;
  content: "";
  margin-right: 15px;
}
.location-details__link a:hover {
  color: #DB2C3C;
}
.location-details__link a:active {
  color: #2F2F2F;
}
.location-details__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.location-details__list li {
  position: relative;
  margin: 0;
  padding: 3px 0 3px 30px;
}
.location-details__list li:before {
  content: "";
  position: absolute;
  top: 7px;
  left: 0;
  display: block;
  width: 17px;
  height: 17px;
  background: url("../../images/check.svg") 0 0 no-repeat;
}
.location-details__list-inline {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.location-details__list-inline li {
  display: inline-block;
  margin: 0;
  padding: 0;
}
.location-details__list-inline li:after {
  content: ", ";
}
.location-details__list-inline li:last-child:after {
  content: none;
}
.location-details__translate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 18px 0;
}
.location-details__translate h2 {
  margin: 0;
}
.location-details__translate button {
  display: flex;
  align-items: center;
  color: #9B9B9B;
  font-size: 12px;
  background: none;
  border: none;
  cursor: pointer;
}
.location-details__translate svg {
  display: block;
  width: 16px;
  height: 16px;
  margin-right: 0.5em;
}
.location-details__translate + p {
  margin-top: 0.25em;
}

@media screen and (min-width: 767px) {
  .location-details {
    max-width: 900px;
    margin: 0 auto;
    font-size: 18px;
    line-height: 30px;
  }
  .location-details h2 + .location-details__row h3:first-child {
    margin-top: 0;
  }
  .location-details__header {
    margin: 20px 0 70px;
  }
  .location-details__header--nomap {
    margin-bottom: 40px;
  }
  .location-details__watchlist {
    margin-top: 10px;
  }
  .location-details__name {
    font-size: 32px;
    line-height: 1.3;
  }
  .location-details__section {
    margin-top: 40px;
    padding-top: 20px;
  }
  .location-details__section-margin {
    margin-top: 40px !important;
  }
  .location-details__map {
    margin: 0;
    padding: 0;
  }
  .location-details__map > div {
    width: 100%;
    height: 325px;
  }
  .location-details__address {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .location-details__link {
    margin: 7px 0 !important;
  }
  .location-details__row {
    display: flex;
    gap: 50px;
  }
  .location-details__column {
    width: 45%;
  }
  .location-details__column + .location-details__column {
    width: 55%;
  }
  .location-details__list--two-columns {
    columns: 2;
    gap: 50px;
  }
  .location-details__list li {
    padding: 4px 0 4px 30px;
    line-height: 24px;
  }
  .location-details__list li:before {
    top: 9px;
  }
}
.admin-form__error {
  background: #DB2C3C;
  color: #fff;
  padding: 8px 23px 7px;
  position: relative;
  font-size: 0.8em;
  margin-bottom: 35px;
}
.admin-form__review {
  background: rgba(146, 219, 44, 0.2509803922);
  border: 2px solid #92db2c;
  padding: 8px 23px 7px;
  position: relative;
  font-size: 0.8em;
  margin-bottom: 35px;
}
.admin-form__geocode-map {
  width: 100%;
}
.admin-form__geocode-map > div {
  height: 100%;
  min-height: 250px;
}
.admin-form__geocode-button {
  position: relative;
  display: inline-flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 0 45px;
  color: #B50717;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  text-decoration: none;
  text-transform: uppercase;
  background: none;
  border: 2px solid #B50717;
  border-radius: 1px;
  cursor: pointer;
  width: 120px;
  height: 50px;
  border: none;
  width: 100% !important;
}
.admin-form__geocode-button:hover {
  color: #fff;
  background: #DB2C3C;
  border-color: #DB2C3C;
}
.admin-form__geocode-button:active {
  background: #2F2F2F;
  border-color: #2F2F2F;
}
.admin-form__geocode-button svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.admin-form__geocode-button svg rect {
  fill: none;
  stroke: #B50717;
  stroke-width: 4;
  stroke-dasharray: 422, 0;
  transition: all 0.35s linear;
}
.admin-form__geocode-button.loading svg rect {
  animation: link-loading 3s infinite cubic-bezier(0.19, 1, 0.22, 1);
}
.admin-form__geocode-button:hover svg rect {
  stroke: #DB2C3C;
}
.admin-form__geocode-button:active svg rect {
  stroke: #2F2F2F;
}
@keyframes link-loading {
  0% {
    stroke-dasharray: 15;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 15;
    stroke-dashoffset: 252.5;
  }
}
.admin-form fieldset#ctrl_languages {
  columns: 2;
}
.admin-form .widget-submit {
  margin-top: 35px;
}

@media screen and (min-width: 767px) {
  .admin-form__geocode {
    display: flex;
    gap: 20px;
  }
  .admin-form__geocode-map {
    width: 70%;
  }
  .admin-form__geocode-fields {
    width: 30%;
  }
  .admin-form__geocode-explanation {
    margin-top: 0;
  }
  .admin-form__geocode-button {
    position: relative;
    display: inline-flex;
    height: 50px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 0 45px;
    color: #B50717;
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    text-decoration: none;
    text-transform: uppercase;
    background: none;
    border: 2px solid #B50717;
    border-radius: 1px;
    cursor: pointer;
    width: 200px;
    height: 50px;
    border: none;
  }
  .admin-form__geocode-button:hover {
    color: #fff;
    background: #DB2C3C;
    border-color: #DB2C3C;
  }
  .admin-form__geocode-button:active {
    background: #2F2F2F;
    border-color: #2F2F2F;
  }
  .admin-form__geocode-button svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .admin-form__geocode-button svg rect {
    fill: none;
    stroke: #B50717;
    stroke-width: 4;
    stroke-dasharray: 422, 0;
    transition: all 0.35s linear;
  }
  .admin-form__geocode-button.loading svg rect {
    animation: link-loading 3s infinite cubic-bezier(0.19, 1, 0.22, 1);
  }
  .admin-form__geocode-button:hover svg rect {
    stroke: #DB2C3C;
  }
  .admin-form__geocode-button:active svg rect {
    stroke: #2F2F2F;
  }
  @keyframes link-loading {
    0% {
      stroke-dasharray: 15;
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dasharray: 15;
      stroke-dashoffset: 372.5;
    }
  }
  .admin-form fieldset#ctrl_offering {
    columns: 2;
  }
  .admin-form .widget-submit {
    margin-top: 70px;
    display: flex;
    justify-content: center;
  }
  .admin-form .widget-submit button {
    width: auto;
    padding-left: 100px;
    padding-right: 100px;
  }
}
@media screen and (min-width: 1220px) {
  .admin-form button.geocode {
    position: relative;
    display: inline-flex;
    height: 50px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 0 45px;
    color: #B50717;
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    text-decoration: none;
    text-transform: uppercase;
    background: none;
    border: 2px solid #B50717;
    border-radius: 1px;
    cursor: pointer;
    width: 350px;
    height: 50px;
    border: none;
  }
  .admin-form button.geocode:hover {
    color: #fff;
    background: #DB2C3C;
    border-color: #DB2C3C;
  }
  .admin-form button.geocode:active {
    background: #2F2F2F;
    border-color: #2F2F2F;
  }
  .admin-form button.geocode svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .admin-form button.geocode svg rect {
    fill: none;
    stroke: #B50717;
    stroke-width: 4;
    stroke-dasharray: 422, 0;
    transition: all 0.35s linear;
  }
  .admin-form button.geocode.loading svg rect {
    animation: link-loading 3s infinite cubic-bezier(0.19, 1, 0.22, 1);
  }
  .admin-form button.geocode:hover svg rect {
    stroke: #DB2C3C;
  }
  .admin-form button.geocode:active svg rect {
    stroke: #2F2F2F;
  }
  @keyframes link-loading {
    0% {
      stroke-dasharray: 15;
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dasharray: 15;
      stroke-dashoffset: 597.5;
    }
  }
}
.share__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #2F2F2F;
  opacity: 0.2;
  z-index: 100;
}
.share__content {
  position: fixed;
  top: 50vh;
  left: 50vw;
  width: calc(100vw - 50px);
  max-width: 600px;
  padding: 30px 50px;
  transform: translate3d(-50%, -50%, 0);
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1607843137);
  z-index: 102;
}
.share__title {
  font-weight: 700;
}
.share__link {
  font-size: 0.9em;
  overflow-wrap: break-word;
}
.share__close {
  position: absolute;
  top: 15px;
  right: 20px;
  border: none;
  background: none;
  cursor: pointer;
}
.share__actions {
  margin-top: 2em;
  text-align: center;
}
.share__clipboard {
  position: relative;
  display: inline-flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 0 45px;
  color: #B50717;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  text-decoration: none;
  text-transform: uppercase;
  background: none;
  border: 2px solid #B50717;
  border-radius: 1px;
  cursor: pointer;
}
.share__clipboard:hover {
  color: #fff;
  background: #DB2C3C;
  border-color: #DB2C3C;
}
.share__clipboard:active {
  background: #2F2F2F;
  border-color: #2F2F2F;
}

.ce_hyperlink,
.ce_download {
  margin: 5px 0 35px 0;
  padding: 0;
}
.ce_hyperlink a,
.ce_download a {
  position: relative;
  display: inline-block;
  min-height: 25px;
  padding-left: 45px;
  color: #B50717;
  background: none;
  border: none;
  cursor: pointer;
}
.ce_hyperlink a:before,
.ce_download a:before {
  content: "";
  position: absolute;
  width: 24px;
  height: 25px;
  left: 0px;
  top: 2px;
  background: url("../images/link-forward--infodrog.svg") 0 0/125px 25px no-repeat;
}
.ce_hyperlink a:hover,
.ce_download a:hover {
  color: #DB2C3C;
}
.ce_hyperlink a:hover:before,
.ce_download a:hover:before {
  background-position: -25px 0;
}
.ce_hyperlink a:active,
.ce_download a:active {
  color: #2F2F2F;
}
.ce_hyperlink a:active:before,
.ce_download a:active:before {
  background-position: -50px 0;
}
.ce_hyperlink a[target=_blank]:before,
.ce_download a[target=_blank]:before {
  background-image: url("../../images/link-new--infodrog.svg");
}
.ce_hyperlink.button,
.ce_download.button {
  margin-top: 35px;
  text-align: center;
}
.ce_hyperlink.button a,
.ce_download.button a {
  position: relative;
  display: inline-flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 0 45px;
  color: #B50717;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  text-decoration: none;
  text-transform: uppercase;
  background: none;
  border: 2px solid #B50717;
  border-radius: 1px;
  cursor: pointer;
}
.ce_hyperlink.button a:hover,
.ce_download.button a:hover {
  color: #fff;
  background: #DB2C3C;
  border-color: #DB2C3C;
}
.ce_hyperlink.button a:active,
.ce_download.button a:active {
  background: #2F2F2F;
  border-color: #2F2F2F;
}
.ce_hyperlink.button a:before,
.ce_download.button a:before {
  content: none !important;
}
.ce_hyperlink.back,
.ce_download.back {
  margin-bottom: 35px;
}
.ce_hyperlink.back:after,
.ce_download.back:after {
  display: table;
  clear: both;
  content: "";
}
.ce_hyperlink.back a,
.ce_download.back a {
  position: relative;
  display: inline-block;
  min-height: 25px;
  padding-left: 45px;
  color: #B50717;
  background: none;
  border: none;
  cursor: pointer;
  color: #595959;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.ce_hyperlink.back a:before,
.ce_download.back a:before {
  content: "";
  position: absolute;
  width: 24px;
  height: 25px;
  left: 0px;
  top: -3px;
  background: url("../images/link-forward--infodrog.svg") 0 0/125px 25px no-repeat;
  transform: rotateZ(180deg);
}
.ce_hyperlink.back a:hover,
.ce_download.back a:hover {
  color: #DB2C3C;
}
.ce_hyperlink.back a:hover:before,
.ce_download.back a:hover:before {
  background-position: -25px 0;
}
.ce_hyperlink.back a:active,
.ce_download.back a:active {
  color: #2F2F2F;
}
.ce_hyperlink.back a:active:before,
.ce_download.back a:active:before {
  background-position: -50px 0;
}
.ce_hyperlink--file a:before,
.ce_hyperlink--file a[target=_blank]:before,
.ce_download--file a:before,
.ce_download--file a[target=_blank]:before {
  background-image: url("../../images/link-file--infodrog.svg");
}
.ce_hyperlink--email a:before,
.ce_hyperlink--email a[target=_blank]:before,
.ce_download--email a:before,
.ce_download--email a[target=_blank]:before {
  background-image: url("../../images/link-email--infodrog.svg");
}
.ce_hyperlink--phone a:before,
.ce_hyperlink--phone a[target=_blank]:before,
.ce_download--phone a:before,
.ce_download--phone a[target=_blank]:before {
  background-image: url("../../images/link-phone--infodrog.svg");
}
.ce_hyperlink + .ce_download,
.ce_hyperlink + .ce_hyperlink,
.ce_download + .ce_download,
.ce_download + .ce_hyperlink {
  overflow: hidden;
  margin-top: -30px;
}

.ce_text + .ce_download,
.ce_text + .ce_hyperlink {
  margin-top: calc(-35px + 1em);
}

.ce_text + .ce_hyperlink > .ce_headline,
.ce_hyperlink + .ce_hyperlink > .ce_headline {
  margin-top: 30px;
}

@media screen and (min-width: 767px) {
  .section-inherited .ce_hyperlink, .booklet .ce_hyperlink,
  .section-inherited .ce_download,
  .booklet .ce_download {
    padding-left: 20px;
    padding-right: 20px;
  }
  .ce_text + .ce_hyperlink > .ce_headline,
  .ce_hyperlink + .ce_hyperlink > .ce_headline {
    margin-top: 50px;
  }
}
@media screen and (min-width: 1220px) {
  .ce_hyperlink a:before,
  .ce_download a:before {
    top: 4px;
  }
}